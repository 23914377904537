.modal-style {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: auto;
    margin-right: -40%;
    transform: translate(-50%, -50%);
    background:black;
    border-radius:10px
    }

.select-symbol {
    cursor:pointer;
    background-color: rgb(50, 50, 50);
    color: white;
    display: flex;
    position: fixed;
    z-index: 999;
    border: 5 dotted white !important;
}

.select-symbol ul{
    border-bottom: 5 dotted white !important;
    margin:0 5pt;
}

.select-symbol ul li{
    padding: 5px 0;
}
.select-symbol ul li:hover{
    background-color:  rgb(65, 63, 63);
}