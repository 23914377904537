:root{
	--color-fuente-principal: rgb(157, 151, 151) !important;
	--color-fuente-secundaria: #fff;
	--color-fuente-titulos: #fff;
	--color-fuente-subtitulos: #fff;
	--color-fondo: #fff;
	--color-menu: #c8d8ef;
	--color-recuadro: #e9eaeb;
	/* Header */
	--color-background-header: #222;
	--color-fuente-header: #fff;
	--color-background-select-header: #434343;
	--color-account-select-header: #0c99cd;
	/* Botones */
	--color-buttom-paso: #fff;
	--color-buttom-cancelar: #fff;
	--color-buttom-guardar: #fff;
	--color-buttom-enviar: rgb(49 206 116);
	--color-buttom-fuente: #fff;

	/* customs colors */
	--ag-color-main: black;
}
.App {
	text-align: center;
}

.App-tv-logo {
	height: 45px;
}

.App-react-logo {
	display: block;
	height: 62px;
}

.App-title {
	display: block;
	font-size: 1.5em;
}

.btn-success {
    color: white !important;
}

.btn-dark {
    color: white !important;
}

.text-white {
	color: #fff !important;
	/* background-color: #fff !important; */
}

.row div{
    justify-items: center !important;
    align-items: center !important;
}

.nav-tabs .nav-link {
	background-color: rgb(0, 0, 0) !important;
}

.nav-tabs  .active {
	background-color: rgb(0, 0, 0) !important;
	color: white !important;
}

.controlate {
	overflow: auto;
	/* padding: 12px 12px; */
}

.alert-webtrade{
	width: 60%;
	height: auto;
	z-index: 9999 !important;
	position: fixed;
	margin-left:20%;
	top: 40%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	text-align: center;
	vertical-align: middle;
	font-size: 20pt;
	color: #ffffff;
    background-color: #000000;
    border-color: #ffffff;
}

.alertBackground{
	width: 100%;
	height: 100%;
	z-index: 8888 !important;
	position: fixed;
	margin-left:0;
	top: 0;
	background-color: #000 !important;
	opacity: 75%;
}

.fade{
	z-index: -100 !important;
}
.show{
	z-index: 100;
}


/*custom*/
.backgroundgen{
	background-color: rgb(0, 0, 0) !important;
}

.gen-fontwhite{
	color:white !important;
}

.get-btn-monto{
	color: #000;
    background-color: #31ce74;
    border-color: #000000;
}

.gen-input-text{
	border-color: white;
    background: black;
    COLOR: WHITE;
}

.gen-input-text:focus{
	background: black;
	color:white;
}

.gen-text-color-prices{
	color:white;
}

.gen-button{
	background-color: #26a69a;
	border-color: #26a69a;
}

.gen-button:hover{
	background-color: #197068;
	border-color: #26a69a;
}

.gen-header{
	margin-bottom: 0px !important;
	background-color: black !important;
}


@media screen and (max-width: 940px){
    .ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
        margin-right: -90% !important;
    }
}