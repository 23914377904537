
.form-model div{
    margin: 8px 0;
}
.form-model label{
    margin-right: 10px;
    font-weight: 700;
    color: rgb(191, 191, 191);
}
.form-model input[type="text"],input[type="number"], textarea, select{
    width: 100%;
    height: 35px;
    padding: 5px 15px;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
}
textarea{
    min-height: 60px !important;
}
.diagonal{
    font-weight: 600;
    font-size: 50pt;
    padding-top: -50px !important;
}
.option-operation{
    text-align: center;
    color: #525252;
}
.option-operation h3{
    font-size: 30pt !important;
}
.option-operation button{
    width: 100%;
    color: #fff;
    font-weight: 500;
}
.option-operation .sell{
    background: #dd5f5f !important;
}
.option-operation .buy{
    background: #579f54 !important;
}
.option-operation .sell:hover{
    background: #ea3232 !important;
    color: #fff;
}
.option-operation .buy:hover{
    background: #239d1f !important;
    color: #fff;
}
.ReactModalPortal .btn-danger{
    width: 100%;
}
.trade-selected{
    font-weight: 500;
    color: #239d1f !important;
    /* border-color: #239d1f; */
    /* border-style: dotted 3; */
}