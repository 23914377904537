* {
    border-radius: 5px;
}

body.thumb {
    transform: scale(0.4);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.App-header {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	background-color: var(--color-background-header);
	color: var(--color-fuente-header);
}

.App-header img{
    max-width: 10vw;
}

main {
    min-height: 80vh;
}

[class*='container'] {
    /* border: 1px dashed #eee; */
    background-clip: content-box;
}

.row [class*='col'] {
    /* background-color: #efefef; */
    background-clip: content-box;
}

.nav-item {
    /* background-color: #eeeeff; */
    background-color: #0e0e81;
    background-clip: content-box;
}

.account-type{
    padding: 0.5em;
    color: black;
    font-weight: 500;
    /* background-color: #0e0e81; */
}
/* 
.account-type input{
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 2em;
    border-radius: 2em !important;
    background-position: left center;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
    transition: background-position .15s ease-in-out;
}

.account-type input:checked{
    background-position: right center;
    background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e) !important;
}
 */


/* menu desplegable */
/* .nav {
    align-items: flex-end !important;
    justify-items: right !important;
    justify-content: right !important;
    right:4.5vw !important;
} */

ul, ol {
    list-style:none;
}

.nav > li {
    float:left;
}

.nav li div {
    color:var(--color-fuente-header);
    text-decoration:none;
    padding:10px 12px;
    display:block;
}

.nav li div:hover {
    background-color:var(--color-background-select-header);
}

.nav li ul {
    display:none;
    position:absolute;
    min-width:140px;
    background-color: var(--color-background-header);
    cursor: pointer;
    /* right:35px; */
    right:2vw;
}


.nav li:hover > ul {
    display:block;
}

.nav li ul li {
    position:relative;
}
.nav li ul li:hover {
    background-color: var(--color-background-select-header);
}

.nav li ul li ul {
    right:-140px;
    top:-15px;
}

.on{
    color:var(--color-fuente-header) !important;
    background-color: var(--color-account-select-header) !important;
}

.logout-style{
    cursor: pointer;
    text-align: left;
    margin-left: 10px;
}

@media screen and (max-width: 600px){
    .App-header img{
        max-width: 35vw;
    }
    .nav li ul li ul {
        right:-140px;
        top:0px;
    }
}