body{
    background-color:black;
}

.login-component button {
    color: var(--color-buttom-fuente) !important;
    background-color: var(--color-buttom-enviar) !important;
}

.login-component .login{
    background-color: var(--ag-color-main) !important;
}

.login-component .row {
    margin: 10px 10px;
}
.login-component .row div{
    margin-top: 0px;
    margin-bottom: 0px;
    justify-items: center !important;
    align-items: center !important;
}

.login-component .alert{
    width: 30%;
    margin-left: 32%;
}

.login-component img{
    max-width: 15vw;
}

.labelloginuser{
    margin-top: 0px !important;
}

.labelloginpassword{
    margin-top: -10px !important;
}

.textlabellogin{
    color:white;
    text-align: left;
}

.logologin{
    margin-bottom: 5px;
}

.inputuserlogin{
    background: black;
    color: white;
}

.inputpasswordlogin{
    background: black;
    color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .login-component img{
        max-width: 60vw;
    }

    .login-component .login{
        margin-top: 25vw;
    }

    .btnlogin{
        width: 100%;
    }

    .gen-input-text{
        border-color: white;
        background: black;
        COLOR: WHITE;
    }
    
    .gen-input-text:focus{
        background: black;
        color:white;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .login-component img{
        max-width: 40vw;
    }

    .login-component .login{
        margin-top: 25vw;
    }

    .btnlogin{
        width: 100%;
    }

    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .login-component img{
        max-width: 40vw;
    }

    .login-component .login{
        margin-top: 15vw;
    }

    .btnlogin{
        width: 70%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .login-component img{
        max-width: 24vw;
    }


    .login-component .login{
        margin-top: 8vw;
    }
    
    .btnlogin{
        width: 70%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .login-component .login{
        margin-top: 5vw;
    }

    .btnlogin{
        width: 70%;
    }
}